import React, { useState } from 'react';
import { USER_STATE, USER_ACTION_TYPES } from '../../constants';
import { useImmerReducer } from 'use-immer';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USERS, CREATE_USER, UPDATE_USER, REMOVE_USER, SEND_INVITE } from './userQueries';

import { 
  ListItem,
  List,
  ListItemText,
  ListItemButton,
  TextField,
  Typography,
  Divider,
  Button,
  Stack,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  CircularProgress
 } from '@mui/material'
 import { green } from '@mui/material/colors'
import DeleteIcon from "@mui/icons-material/Delete";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import { Grid } from '../../components/themedGrid';

const { FIRST_NAME, LAST_NAME, EMAIL, START_DATE, INITIALIZE_STATE, ROLE } = USER_ACTION_TYPES;

const userReducer = (draft, action) => {
  const { type, value } = action;
  switch (type) {
    case FIRST_NAME:
      draft[FIRST_NAME] = value;
      return draft;
    case LAST_NAME:
      draft[LAST_NAME] = value;
      return draft;
    case EMAIL:
      draft[EMAIL] = value;
      return draft;
    case START_DATE:
      draft[START_DATE] = value;
      return draft;
    case ROLE:
      draft[ROLE] = value;
      return draft;
    case INITIALIZE_STATE:
      return value;
    default:
      break;
  }
};

const UsersPage = (props) => {
  const [state, dispatch] = useImmerReducer(userReducer, USER_STATE);
  const [selectedUserId, setSelectedUserId] = useState();
  const [inviteSending, setInviteSending] = useState({});
  const [inviteSent, setInviteSent] = useState({});
  const { firstName, lastName, email, startDate, role } = state;


  const handleChange = (value, type) => {
    dispatch({ value, type });
  };
  const { data: queryData, loading: queryLoading, error: queryError, refetch } = useQuery(GET_USERS)

  const [createUser, { data, loading, error }] = useMutation(CREATE_USER)
  const [updateUser] = useMutation(UPDATE_USER)
  const [removeUser] = useMutation(REMOVE_USER)
  const [sendInvite] = useMutation(SEND_INVITE)
  const handleSaveUser = async () => {
    if(selectedUserId) {
      await updateUser({
        variables: {
          userId: selectedUserId,
          firstName,
          lastName,
          email,
          startDate,
          role,
        }
      })
    } else {
      await createUser({
        variables: {
          firstName,
          lastName,
          email,
          startDate,
          role,
        }
      })
    }
   

    await refetch()
    dispatch({ type: INITIALIZE_STATE, value: USER_STATE })
    setSelectedUserId()
  }

  const editUser = async (user) => {
    setSelectedUserId(user.id)
    dispatch({ 
      type: INITIALIZE_STATE, 
      value: {

        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        startDate: user.startDate,
        role: user.role,
      }
    })
  }

  const handleRemoveUser = async (userId) => {
    await removeUser({
      variables: {
        userId
      }
    })
    dispatch({ type: INITIALIZE_STATE, value: USER_STATE })
    await refetch()
  }

  const handleCancel = () => {
    setSelectedUserId()
    dispatch({ type: INITIALIZE_STATE, value: USER_STATE })
  }

  const handleSendInvite = async (userId, e) => {
    e.stopPropagation()
    setInviteSending({...inviteSending, [userId]: true})
    const response =await sendInvite({
      variables: {
        userId
      }
    })
    await new Promise(resolve => setTimeout(resolve, 1000));

    const newInviteSending = {...inviteSending}
    delete newInviteSending[userId]
    setInviteSending(newInviteSending)
    if(response.data.sendInviteEmail.success) {
      setInviteSent({...inviteSent, [userId]: true})
    }
  }

  if (queryLoading) return <p>Loading...</p>;

  if (queryError) return <p>Error :</p>;

  const users = queryData.users?.map(u => {
    const labelId = `checkbox-list-label-${u}`;
    return (
      <React.Fragment key={u.id}>
        <ListItem 
          disablePadding 
          onClick={() => editUser(u)}
          secondaryAction={
            <>
              {inviteSending[u.id] ? (
                <CircularProgress
                  size={25}
                  sx={{
                    color: green[500],
                  }}
                />
              ) : (
                <>
                  {u.role === 'Admin' && (
                    <>
                      {inviteSent[u.id] ? (
                        <Tooltip title="Invite Sent">
                          <TaskAltIcon sx={{
                            color: green[500]
                          }}/>
                        </Tooltip>

                      ) : (
                        <Tooltip title="Send Invite">
                          <IconButton
                            aria-label="send invite"
                            onClick={(e) => handleSendInvite(u.id, e)}
                          >
                          <ForwardToInboxIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  )}  
                </>
              )}
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveUser(u.id)}
                >
                  <DeleteIcon />
                </IconButton>
            </>
          }
        >
          <ListItemButton>
            <ListItemText id={labelId} primary={`${u.firstName} ${u.lastName}`}/>
          </ListItemButton> 
        </ListItem>
        <Divider />
      </React.Fragment>
    );
  })

  return (
    <>
      <Grid container spacing={2} height='100vh'>
        <Grid item xs>
          <Grid container spacing={2} sx={{marginBottom: '3vh'}}>
            <Grid item xs={12}>
              <Typography variant='h5'>Create/Edit User</Typography>
            </Grid>
            
            <Grid item xs={6}>
              <TextField 
                fullWidth
                id="firstName" 
                label="First Name" 
                variant="outlined" 
                value={state.firstName}
                onChange={({ target }) => handleChange(target.value, FIRST_NAME)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                fullWidth
                id="lastName" 
                label="Last Name" 
                variant="outlined" 
                value={state.lastName}
                onChange={({ target }) => handleChange(target.value, LAST_NAME)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                fullWidth
                id="email" 
                label="Email" 
                variant="outlined" 
                value={state.email}
                onChange={({ target }) => handleChange(target.value, EMAIL)}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  disableFuture
                  label="Start Date"
                  inputFormat="MM/dd/yyyy"
                  value={state.startDate ? new Date(state.startDate) : null}
                  onChange={(value) => handleChange(value, START_DATE)}
                  slotProps={{ textField: { fullWidth: true } }}
                  renderInput={(params) => <TextField {...params} error={false} fullWidth/>}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-select"
                  defaultValue={state.role ?? ''}
                  value={state.role}
                  label="Role"
                  onChange={({ target }) => handleChange(target.value, ROLE)}
                >
                  <MenuItem value={'Admin'}>Admin</MenuItem>
                  <MenuItem value={'Standard'}>Standard</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
          </Grid>
          <Stack direction="row" spacing={2} >
            <Button onClick={() => handleSaveUser()} variant="contained">Save</Button>
            <Button variant="contained" onClick={() => handleCancel()}>Cancel</Button>
          </Stack>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs>                          
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant='h5'>Users</Typography>
            </Grid>
            <Grid item xs={12}>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {users}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UsersPage;
