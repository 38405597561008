import { action, thunk } from "easy-peasy";
import { UserRoles, isValidRole } from "../../constants/users";

const userModel = {
  // State
  role: null,
  email: null,
  roleCheckIsLoading: false,
  error: null,

  // Actions
  setRole: action((state, payload) => {
    if (isValidRole(payload)) {
      console.log("setting role", payload);
      state.role = payload;
    } else {
      console.error(`Invalid role: ${payload}`);
      state.role = UserRoles.NON_PRIVILEGED;
    }
  }),
  setRoleCheckIsLoading: action((state, payload) => {
    state.roleCheckIsLoading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setEmail: action((state, payload) => {
    state.email = payload;
  }),

  // Thunks
  fetchUserRole: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setRoleCheckIsLoading(true);

    try {
      // TODO: Make this endpoint
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${payload.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userKey: payload.userKey,
            inviteCode: payload.inviteCode,
          }),
        }
      );
      const data = await response.json();
      actions.setRole(data.role);
      actions.setRole(UserRoles.SUPER_ADMIN); //TODO: Remove this
    } catch (error) {
      actions.setError(error.message);
    } finally {
      actions.setRoleCheckIsLoading(false);
    }
  }),
};

export default userModel;
