import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useAuth0 } from "@auth0/auth0-react";
import { ApolloProvider } from "@apollo/client";

const createApolloClient = (
  getAccessTokenSilently,
  loginWithRedirect,
  companyOverrideId
) => {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: "https://www.thinktempo.com",
        scope: "openid profile email",
      },
    });

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        ...(companyOverrideId ? { "x-company-key": companyOverrideId } : {}),
      },
    };
  });

  const resetToken = onError(({ response, networkError }) => {
    if (networkError?.statusCode === 401 || networkError?.statusCode === 403) {
      loginWithRedirect();
    }
  });

  return new ApolloClient({
    link: ApolloLink.from([resetToken, authLink, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });
};

const AuthorizedApolloProvider = ({ children }) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const companyOverrideId = localStorage.getItem("companyOverrideId");
  const client = createApolloClient(
    getAccessTokenSilently,
    loginWithRedirect,
    companyOverrideId
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { createApolloClient };
export default AuthorizedApolloProvider;
