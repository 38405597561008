import { createStore } from "easy-peasy";
import userModel from "./model/user";
import companyModel from "./model/company";

const createStoreWithClient = (apolloClient) =>
  createStore(
    {
      user: userModel,
      company: companyModel,
    },
    {
      injections: {
        apolloClient,
      },
    }
  );

export { createStoreWithClient };
