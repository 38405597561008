import { useState } from "react";

import {
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Typography,
  Button,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AddQuestions from "./AddQuestions";
import { useQuery, useMutation } from "@apollo/client";

import {
  ADD_QUESTION_TO_PROFILE,
  REMOVE_QUESTION_FROM_PROFILE,
  ADD_QUESTION_SETS_TO_PROFILE,
  GET_PROFILE_QUESTIONS,
  GET_QUESTION_SETS,
} from "./profileQueries";
const ProfileQuestion = (props) => {
  const { profileId } = props;

  const [showAddSection, setShowAddSection] = useState(false);
  const [questionAddedSuccess, setQuestionAddedSuccess] = useState(false);
  const [checked, setChecked] = useState({});

  // Queries / Mutations
  const { data: questionSetsData } = useQuery(GET_QUESTION_SETS);
  const { loading, data, error } = useQuery(GET_PROFILE_QUESTIONS, {
    variables: { profileId },
  });
  const [addQuestionToProfile] = useMutation(ADD_QUESTION_TO_PROFILE);
  const [addQuestionSetsToProfile] = useMutation(ADD_QUESTION_SETS_TO_PROFILE);
  const [removeQuestionFromProfile] = useMutation(REMOVE_QUESTION_FROM_PROFILE);

  // Data
  const { profile } = data || {};
  const { questionSetsWithQuestions } = questionSetsData || {};
  const questionSetIds = profile?.questionSets?.map((qs) =>
    Number(qs.questionSetId)
  );

  // initialize checked state based on questions in profile
  useEffect(() => {
    const questionSets = data?.profile?.questionSets;
    if (!questionSets || questionSets?.length <= 0) return;

    let profileQuestionIds = {};
    questionSets.forEach((qs) => {
      if (qs?.questions?.length <= 0) return;
      qs?.questions?.forEach((q) => {
        profileQuestionIds[q.id] = q.excluded ? false : true;
      });
    });

    setChecked(profileQuestionIds);
  }, [data]);

  // Event Handler - Add Question Sets to Profile
  const handleAddQuestionSets = async (questionSets) => {
    console.log("ADDING SET --", questionSets);
    if (!profileId || questionSets?.length === 0) return null;

    await addQuestionSetsToProfile({
      variables: {
        profileId,
        questionSetIds: questionSets.map((qs) => qs.id),
      },
      refetchQueries: [
        GET_PROFILE_QUESTIONS, // DocumentNode object parsed with gql
        "GetProfileQuestions", // Query name
      ],
    });

    setQuestionAddedSuccess(true);
  };

  // Event Handler - Add Question to Profile
  const handleAddQuestion = async (questionId) => {
    console.log("ADDING QUESTION --", questionId);
    if (!profileId || !questionId) return null;

    await addQuestionToProfile({
      variables: {
        profileId,
        questionId,
      },
      refetchQueries: [
        GET_PROFILE_QUESTIONS, // DocumentNode object parsed with gql
        "GetProfileQuestions", // Query name
      ],
    });

    setQuestionAddedSuccess(true);
  };

  // Event Handler - Remove Question from Profile
  const handleRemoveQuestion = async (questionId) => {
    if (!profileId || !questionId) return null;

    await removeQuestionFromProfile({
      variables: {
        profileId,
        questionId,
      },
      refetchQueries: [
        GET_PROFILE_QUESTIONS, // DocumentNode object parsed with gql
        "GetProfileQuestions", // Query name
      ],
    });

    setQuestionAddedSuccess(true);
  };

  // Event Handler - Handle checkbox toggle for questions in profile
  const handleCheckboxToggle = async (question) => {
    const value = !checked[question.id];
    if (value) {
      await handleAddQuestion(question.id);
    } else {
      await handleRemoveQuestion(question.id);
    }
    setChecked((checked) => ({ ...checked, [question.id]: value }));
  };

  // Render
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :{error}</p>;

  return (
    <Box style={{ width: "500px", marginTop: "16px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        <Typography variant="h5">Questions</Typography>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => setShowAddSection(!showAddSection)}
        >
          {showAddSection ? "Hide" : "Add"}
        </Button>
      </div>
      {showAddSection && (
        <Paper
          elevation={2}
          sx={{
            p: 2,
            m: 2,
            backgroundColor: "background.paper",
            borderRadius: 1,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Add Question Sets
          </Typography>
          <AddQuestions
            profileQuestionSetIds={questionSetIds}
            addQuestionSets={handleAddQuestionSets}
            questionSets={questionSetsWithQuestions}
          />
        </Paper>
      )}

      <Paper
        elevation={2}
        sx={{
          p: 2,
          m: 2,
          backgroundColor: "background.paper",
          borderRadius: 1,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Profile Questions
        </Typography>
        {profile?.questionSets &&
          profile?.questionSets.length > 0 &&
          profile?.questionSets.map((qs, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>{qs?.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  {qs.questions?.map((q) => {
                    const labelId = `checkbox-list-label-${q}`;

                    return (
                      <ListItem key={q.id} disablePadding>
                        <ListItemButton
                          role={undefined}
                          onClick={() => handleCheckboxToggle(q)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked[q.id] ? true : false}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={q.text}
                            secondary={q.type}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
      </Paper>
    </Box>
  );
};
export default ProfileQuestion;
