import React, { useState } from 'react';
import { GET_PROFILES, CREATE_PROFILE, DELETE_PROFILE } from './profileQueries';
import NewProfileDialog from './../../components/Dialogs/NewProfileDialog'
import { useQuery, useMutation } from '@apollo/client';
import { 
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
  Button,
  IconButton,
  Divider
 } from '@mui/material'
 

 import DashboardIcon from '@mui/icons-material/Dashboard';
 import DeleteIcon from '@mui/icons-material/Delete';
 import AddIcon from '@mui/icons-material/Add';
 import { useNavigate } from 'react-router-dom';


const ProfilesPage = (props) => {
  const navigate = useNavigate();
  const [openNewDialog, setOpenNewDialog] = useState(false);
  const handleClose = () => {
    setOpenNewDialog(false);
  };
  const handleProfileSelect = (profileId) => {

    return navigate(
      '/profile',
      { 
        state: {
          selectedProfileId: profileId
        }
      })
  }

  const [createProfile] = useMutation(CREATE_PROFILE)
  const [deleteProfile] = useMutation(DELETE_PROFILE, {
    refetchQueries: [
      GET_PROFILES, // DocumentNode object parsed with gql
      'GetProfiles' // Query name
    ],
  })

  const handleCreateProfile = async ({ name, numQuestions }) => {
    const response = await createProfile({
      variables: {
        name: name,
        numQuestions: numQuestions,
      }
    })
    if(response?.data?.createProfile) {
      handleProfileSelect(response?.data?.createProfile.id)
    }
  } 

  const { loading, data, error } = useQuery(GET_PROFILES)

  console.log(error)
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const profiles = data.profiles.map(p => (
    <React.Fragment key={`profile-${p.id}`}>
      <ListItem 
        disablePadding 
        secondaryAction={
          <IconButton 
            aria-label="remove"
            edge="end"
            onClick={() => deleteProfile({
              variables: {
                profileId: p.id
              }
            })}
          >
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemButton  onClick={() => handleProfileSelect(p.id)}>
          <ListItemText primary={p.name} />
        </ListItemButton>
      </ListItem>
      <Divider />
    </React.Fragment>
  ));


  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant='h5'>Learning Profiles</Typography>
        </Grid>
        <Grid item xs={4}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setOpenNewDialog(true);
          }}
          startIcon={<AddIcon color='primary'/>}
        >
          
          New
        </Button>
        </Grid>
        <Grid item xs={6}>
          <List>
            {profiles}
          </List>
        </Grid>
      </Grid>
      
      <NewProfileDialog
        openDialog={openNewDialog}
        setOpenDialog={setOpenNewDialog}
        createNewProfile={handleCreateProfile}
      />
    </div>
  );
};
export default ProfilesPage;
