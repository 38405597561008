import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useStoreState, useStoreActions } from "easy-peasy";

import { isAdminOrHigher } from "../constants/users";

export const ProtectedRoute = ({ children }) => {
  const {
    isAuthenticated,
    isLoading: auth0IsLoading,
    user,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const userRole = useStoreState((state) => state.user.role);
  const fetchUserRole = useStoreActions(
    (actions) => actions.user.fetchUserRole
  );
  const roleCheckIsLoading = useStoreState(
    (state) => state.user.roleCheckIsLoading
  );

  React.useEffect(() => {
    const currentParams = new URLSearchParams(window.location.search);
    const userKey = currentParams.get("u");
    const inviteCode = currentParams.get("i");
    console.log("INVITE CODE", inviteCode);
    const getUserRole = async () => {
      if (isAuthenticated && user?.email) {
        try {
          const token = await getAccessTokenSilently();
          await fetchUserRole({
            token,
            userKey,
            inviteCode,
          });
        } catch (error) {
          console.error("Error fetching user role:", error);
        }
      }
    };

    getUserRole();
  }, [isAuthenticated, user, getAccessTokenSilently, fetchUserRole]);

  if (auth0IsLoading || roleCheckIsLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    const currentParams = new URLSearchParams(window.location.search);
    const inviteCode = currentParams.get("i");

    const returnTo = `http${
      process.env.NODE_ENV === "development" ? "" : "s"
    }://${window.location.host}/profiles`;

    loginWithRedirect({
      appState: {
        returnTo: `${returnTo}${
          currentParams.toString() ? `?${currentParams.toString()}` : ""
        }`,
        screen_hint: inviteCode ? "signup" : "login",
      },
    });
    return null;
  }

  if (userRole && !isAdminOrHigher(userRole)) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};
