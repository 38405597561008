import React, { useState } from "react";
import { removeTypename } from "../../helpers/removeTypename";
import {
  GET_PROFILE,
  UPDATE_PROFILE,
  ADD_USERS_TO_PROFILE,
  REMOVE_USER_FROM_PROFILE,
} from "./profileQueries";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation, Link } from "react-router-dom";
import {
  Box,
  Typography,
  Drawer,
  Button,
  Divider,
  Breadcrumbs,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import BallotIcon from "@mui/icons-material/Ballot";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

// Components
import ProfileGeneral from "./ProfileGeneral";
import ProfileQuestion from "./ProfileQuestion";
import ProfileUser from "./ProfileUser";

const ProfilePage = (props) => {
  const location = useLocation();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [userAddedSuccess, setUserAddedSuccess] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState(
    location?.state?.selectedProfileId
  );
  const [usersDrawerOpen, setUsersDrawerOpen] = useState(false);
  const [questionsDrawerOpen, setQuestionsDrawerOpen] = useState(false);

  const [updateProfile, { data: updateProfileData }] =
    useMutation(UPDATE_PROFILE);
  const handleUpdateProfile = async (updatedProfile) => {
    dayjs.extend(utc);
    if (updatedProfile) {
      let time = "";
      if (updatedProfile.time) {
        time = dayjs(updatedProfile.time).hour().toString();
        console.log("CONVERTED TIME --", time);
      }
      console.log("SAVING --", updatedProfile);

      await updateProfile({
        variables: {
          profileId: updatedProfile.id,
          name: updatedProfile.name,
          numQuestions: updatedProfile.numQuestions,
          tempo: {
            ...removeTypename(updatedProfile.tempo),
            time,
            timeZone: updatedProfile.timeZone,
          },
        },
      });

      setUpdateSuccess(true);
      return;
    }
  };

  const [addUsersToProfile] = useMutation(ADD_USERS_TO_PROFILE);
  const handleAddUsers = async (userIds) => {
    console.log("ADDING USERS --", userIds);
    if (!selectedProfileId || userIds?.length === 0) return null;

    await addUsersToProfile({
      variables: {
        profileId: selectedProfileId,
        userIds: userIds,
      },
      refetchQueries: [
        GET_PROFILE, // DocumentNode object parsed with gql
        "GetProfile", // Query name
      ],
    });

    setUserAddedSuccess(true);
  };

  const [removeUserFromProfile] = useMutation(REMOVE_USER_FROM_PROFILE);
  const handleRemoveUser = async (userId) => {
    console.log("REMOVING USER --", userId);
    if (!selectedProfileId || !userId) return null;

    await removeUserFromProfile({
      variables: {
        profileId: selectedProfileId,
        userId,
      },
      refetchQueries: [
        GET_PROFILE, // DocumentNode object parsed with gql
        "GetProfile", // Query name
      ],
    });

    setUserAddedSuccess(true);
  };

  const { loading, data, error } = useQuery(GET_PROFILE, {
    variables: { profileId: selectedProfileId },
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :{error}</p>;
  const profile = updateProfileData?.updateProfile || data.profile;

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            to="/profiles"
            style={{
              display: "flex",
              alignItems: "center",
              color: "grey",
              textDecoration: "underline",
            }}
          >
            Profiles
          </Link>
          <Typography
            color="text.primary"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {profile?.name}
          </Typography>
        </Breadcrumbs>

        <Typography variant="h5" sx={{ fontWeight: 500, marginTop: "10px" }}>
          {profile?.name}
        </Typography>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Button onClick={() => setUsersDrawerOpen(true)} variant="text">
          <GroupIcon />{" "}
          <span
            style={{
              fontSize: "12px",
              marginLeft: "5px",
              color: "grey",
              fontWeight: "bold",
            }}
          >
            USERS
          </span>
        </Button>
        <Divider orientation="vertical" flexItem sx={{ margin: "8px 10px" }} />

        <Button onClick={() => setQuestionsDrawerOpen(true)} variant="text">
          <BallotIcon />
          <span
            style={{
              fontSize: "12px",
              marginLeft: "5px",
              color: "grey",
              fontWeight: "bold",
            }}
          >
            QUESTIONS
          </span>
        </Button>
      </Box>

      <Box>
        <ProfileGeneral
          profile={profile}
          updateProfile={handleUpdateProfile}
          updateSuccess={updateSuccess}
          setUpdateSuccess={setUpdateSuccess}
        />
      </Box>

      <Drawer
        open={usersDrawerOpen}
        onClose={() => setUsersDrawerOpen(false)}
        anchor="right"
        PaperProps={{
          sx: {
            marginTop: "80px", // Account for the AppHeader height
          },
        }}
      >
        <ProfileUser
          users={profile?.users}
          addUsersToProfile={handleAddUsers}
          userAddedSuccess={userAddedSuccess}
          removeUserFromProfile={handleRemoveUser}
        />
      </Drawer>

      <Drawer
        open={questionsDrawerOpen}
        onClose={() => setQuestionsDrawerOpen(false)}
        anchor="right"
        PaperProps={{
          sx: {
            marginTop: "80px", // Account for the AppHeader height
          },
        }}
      >
        <ProfileQuestion profileId={selectedProfileId} />
      </Drawer>
    </Box>
  );
};

export default ProfilePage;
