import { action, thunk } from "easy-peasy";
import { gql } from "@apollo/client";

const GET_COMPANIES = gql`
  query GetCompanies {
    companies {
      id
      name
      uuid
    }
  }
`;

const companyModel = {
  // State
  companyOverrideId: null,
  companies: [],

  // Actions
  setCompanies: action((state, payload) => {
    state.companies = payload;
  }),
  setCompanyOverrideId: action((state, payload) => {
    state.companyOverrideId = payload;
  }),

  // Thunks
  fetchCompanies: thunk(async (actions, payload, { getState, injections }) => {
    const { apolloClient } = injections; // Apollo client should be injected
    try {
      const { data } = await apolloClient.query({
        query: GET_COMPANIES,
      });

      if (data?.companies) {
        actions.setCompanies(data.companies);
        actions.setCompanyOverrideId(data.companies?.[0]?.uuid);
        localStorage.setItem("companyOverrideId", data.companies?.[0]?.uuid);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  }),
};

export default companyModel;
