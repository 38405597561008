import React from "react";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { ThemeProvider } from "styled-components";
import { StoreProvider } from "easy-peasy";
import { useApolloClient } from "@apollo/client";
import { theme, GlobalStyle } from "./styles";
import AuthorizedApolloProvider from "./helpers/apolloClient";
import { createStoreWithClient } from "./store";
import AppRoutes from "./routes";

loadDevMessages();
loadErrorMessages();

const AppWithStore = () => {
  const apolloClient = useApolloClient();
  const store = createStoreWithClient(apolloClient);

  return (
    <StoreProvider store={store}>
      <AppRoutes />
      <GlobalStyle />
    </StoreProvider>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthorizedApolloProvider>
        <AppWithStore />
      </AuthorizedApolloProvider>
    </ThemeProvider>
  );
};

export default App;
