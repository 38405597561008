import React from 'react';
import { useQuery } from '@apollo/client';

import { Chart as ChartJS, LinearScale, Tooltip, Legend, CategoryScale, PointElement, LineElement, BarElement } from "chart.js";
import { Line, Bar } from 'react-chartjs-2';
import { 
  Typography,
  Grid2,
  DateTimePicker,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
 } from '@mui/material'
 import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { GET_COMPANY_DATA } from './dashboardQueries';
import { format, toZonedTime } from "date-fns-tz";
import { GET_USERS } from '../../helpers/apolloQueries/users';

ChartJS.register(CategoryScale, LinearScale, Tooltip, Legend, PointElement, LineElement, BarElement);

const Dashboard = (props) => {
  const [grouping, setGrouping] = React.useState('year');
  // const defaultFromDate = getDefaultFromDate()
  // const [fromDate, setFromDate] = React.useState(startOfWeek(today));
  // const [labels, setLabels] = React.useState([]);
  // const userId = new URLSearchParams(window.location.search).get("u");

  const [user, setUser] = React.useState('');

  const handleChange = (event, newGrouping) => {
    if(!newGrouping || grouping === newGrouping) return
    setGrouping(newGrouping);
  };

  const handleUserChange = ({ target }) => {
    console.log(target)
    setUser(target.value);
  };
  
  const { loading, data, error } = useQuery(GET_COMPANY_DATA, {
    variables: {
      filters: {
        groupBy: grouping,
        userKeys: user ? [user] : []
      }
    }
  })

  const { data: usersData } = useQuery(GET_USERS)

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  let userList
  if(usersData?.users?.length > 0) {
    userList = usersData?.users?.map(u => {
      return (
        <MenuItem
          key={u.id}
          value={u.key}
        >
         {u.firstName} {u.lastName}
        </MenuItem>
      );
    })
  }

  const userSelect = (
    <FormControl sx={{ m: 1, minWidth: 180 }}>
      <InputLabel id="select-user-label">User</InputLabel>
      <Select
        labelId="select-user-label"
        id="select-user"
        value={user}
        label="User"
        onChange={handleUserChange}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        {userList}
      </Select>
    </FormControl>
  )

  const companyData = data?.getCompanyData?.quizData
  const questionSetData = data?.getCompanyData?.questionSetData
  const labels = getLabels(grouping, companyData)
  const scoreChartData = getScoreChartData(labels, companyData)
  const questionSeChartData = getQuestionSetChartData(questionSetData)
  console.log(questionSeChartData)
  console.log(scoreChartData)
  return (
    <div>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
         <Typography variant='h5'>Dashboard</Typography>
        </Grid2>
        <Grid2 size={12} marginBottom={3}>
          <Grid2 container spacing={5} style={{alignItems: 'center'}}>
            <Grid2>
              <ToggleButtonGroup
                color="primary"
                value={grouping}
                exclusive
                onChange={handleChange}
                aria-label="Grouping"
              >
                <ToggleButton value="week">Week</ToggleButton>
                <ToggleButton value="month">Month</ToggleButton>
                <ToggleButton value="year">Year</ToggleButton>
              </ToggleButtonGroup>
            </Grid2>
            <Grid2>
              {userSelect}
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 size={6}>
          <Line data={scoreChartData} />
        </Grid2>
        <Grid2 size={6}>
          <Bar data={questionSeChartData} options={{ indexAxis: 'y' }} />
        </Grid2>
      </Grid2>
    </div>
  );
};

const getScoreChartData = (labels, data) => {
  const scoreData = data?.map(d => d.averageScore * 100)

  const rateData = data?.map(d => (d.quizzesStarted/d.numberOfQuizzes) * 100)
  return {
    labels,
    datasets: [
      {
        label: 'Average Scores',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: scoreData || [],
      },
      {
        label: 'Participation Rate',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(236, 152, 159,0.4)',
        borderColor: 'rgba(236, 152, 159,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(236, 152, 159,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(236, 152, 159,1)',
        pointHoverBorderColor: 'rgba(236, 152, 159,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: rateData || [],
      },
    ],
  };

}

const getQuestionSetChartData = (data) => {

  const labels = data?.map(d => d.questionSetName)
  const percentage = data?.map(d => d.correctPercentage)
  const dataSets = data?.map(d => ({
      axis: 'y',
      backgroundColor: 'rgba(236, 152, 159,0.2)',
      borderColor: 'rgba(236, 152, 159,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(236, 152, 159,0.4)',
      hoverBorderColor: 'rgba(236, 152, 159,1)',
      data: percentage || [],
    }
  ))

  return {
    indexAxis: 'y',
    labels,
    datasets: [{
      label: 'Question Set Performance',
      axis: 'y',
      backgroundColor: 'rgba(236, 152, 159,0.2)',
      borderColor: 'rgba(236, 152, 159,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(236, 152, 159,0.4)',
      hoverBorderColor: 'rgba(236, 152, 159,1)',
      data: percentage || [],
    }]
  };
}

const getLabels = (grouping, data) => {
  let labels = []
  if(data?.length === 0 || !data.length) return []
  if(grouping === 'week') {
    labels = data?.map(d => {
       return formatInTimeZone(new Date(d.date), "EEE", "UTC")
    })
  } else if(grouping === 'month') {
    labels = data?.map(d => {
       return formatInTimeZone(new Date(d.date), "MM/dd", "UTC")
    })
  } else if(grouping === 'year') {
    labels = data?.map(d => {
      return formatInTimeZone(new Date(d.date), "MMMM", "UTC")
    })
  }
  return labels
}

const formatInTimeZone = (date, fmt, tz) =>
  format(toZonedTime(date, tz), 
         fmt, 
         { timeZone: tz });

export default Dashboard;

