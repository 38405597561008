import * as React from "react";
import { Select, MenuItem } from "@mui/material";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { createApolloClient } from "../helpers/apolloClient";

export const CompanySelect = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const setCompanyOverrideId = useStoreActions(
    (actions) => actions.company.setCompanyOverrideId
  );
  const client = useApolloClient();

  const companyOverrideId = useStoreState(
    (state) => state.company.companyOverrideId
  );
  const fetchCompanies = useStoreActions(
    (actions) => actions.company.fetchCompanies
  );
  const companies = useStoreState((state) => state.company.companies);

  React.useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  if (!companies?.length) return null;

  return (
    <>
      <p style={{ fontSize: "10px" }}>COMPANY</p>
      <Select
        autoWidth
        value={companyOverrideId}
        size="small"
        style={{ marginRight: "10px" }}
        onChange={async (e) => {
          setCompanyOverrideId(e.target.value);
          localStorage.setItem("companyOverrideId", e.target.value);

          // Update the Apollo client with the new company ID
          const newClient = createApolloClient(
            getAccessTokenSilently,
            loginWithRedirect,
            e.target.value
          );
          client.setLink(newClient.link);
          await client.resetStore();
        }}
        MenuProps={{
          sx: { zIndex: 4000 }, // Higher than header's 3000
        }}
      >
        {companies.map((company) => (
          <MenuItem key={company.name} value={company.uuid}>
            {company.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
