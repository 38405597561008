import React from "react";
import { Paper, Button } from "@mui/material";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Paper
          elevation={3}
          sx={{
            p: 3,
            m: 2,
            textAlign: "center",
            backgroundColor: "#ffebee",
            // Center the paper on the page
            position: "absolute",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div style={{ color: "#d32f2f", marginBottom: "1rem" }}>
            An unexpected error occurred. Please contact your company
            administrator.
          </div>
          <Button variant="contained" onClick={() => window.location.reload()}>
            Reload Page
          </Button>
        </Paper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
