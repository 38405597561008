import * as React from "react";

import {
  ListItem,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  IconButton,
  Typography,
  Button,
  Box,
  Divider,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import AddUsers from "./AddUser";
import { useEffect, useState } from "react";

const ProfileUser = ({ users, addUsersToProfile, removeUserFromProfile }) => {
  const [showAddSection, setShowAddSection] = useState(false);

  const [userIds, setUserIds] = React.useState(users?.map((u) => Number(u.id)));

  useEffect(() => {
    setUserIds(users?.map((u) => Number(u.id)));
  }, [users]);

  return (
    <Box style={{ width: "500px", marginTop: "16px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        <Typography variant="h5">Users</Typography>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => setShowAddSection(!showAddSection)}
        >
          {showAddSection ? "Hide" : "Add"}
        </Button>
      </div>

      {users && users.length > 0 && (
        <List>
          {users.map((u) => (
            <ListItem
              key={u.id}
              disablePadding
              secondaryAction={
                <IconButton
                  aria-label="remove"
                  onClick={() => removeUserFromProfile(u.id)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemButton onClick={() => console.log(u.id)}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={`${u.firstName} ${u.lastName}`} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}

      {showAddSection && (
        <Box style={{ marginTop: "50px" }}>
          <Divider />
          <AddUsers profileUserIds={userIds} addUsers={addUsersToProfile} />
        </Box>
      )}
    </Box>
  );
};

export default ProfileUser;
