import * as React from "react";

import {
  // ListItem,
  // List,
  // ListItemText,
  // ListItemButton,
  // ListItemIcon,
  // Typography,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  // Grid,
  Button,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import { useQuery } from "@apollo/client";
import { GET_QUESTION_SETS } from "./profileQueries";
import { useEffect, useCallback } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddQuestions = (props) => {
  const { questionSets, addQuestionSets } = props;
  const [checked, setChecked] = React.useState(props.profileQuestionIds || []);
  const [selectedSets, setSelectedSets] = React.useState([]);

  const handleBankCheck = (value) => {
    // event.stopPropagation();
    setSelectedSets(value);
  };

  const handleSave = async () => {
    console.log("SAVING -- ", selectedSets);
    await addQuestionSets(selectedSets);
    setSelectedSets([]);
  };

  useEffect(() => {
    const ids = props.profileQuestionIds?.map((q) => parseInt(q));

    setChecked(ids);
  }, [props.profileQuestionIds]);

  return (
    <div>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={questionSets || []}
        disableCloseOnSelect
        value={selectedSets}
        onChange={(_, newValue) => handleBankCheck(newValue)}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search"
            placeholder="Added Question Sets"
            variant="standard"
          />
        )}
      />
      {selectedSets?.length > 0 && (
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleSave()}
            startIcon={<SaveIcon color="primary" />}
            size="small"
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};
export default AddQuestions;
